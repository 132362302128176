body {
  background-color: #f1f6f8 !important;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDataGrid-filterFormOperatorInput {
  display: none !important;
}

@font-face {
  font-family: "PrintFont";
  src: local("PrintFont"), url("./PrintFont.ttf") format("opentype");
}

/* Gunakan @font-face untuk font lokal */
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Global Tabs Navigasi */
.MuiTabs-root {
  min-height: auto !important;
}

.MuiTabs-root .MuiButtonBase-root {
  min-height: auto !important;
  padding: 11px 16px !important;
}

/*  GLOBAL DATAGRID FOR PRIORITAS */
.datagrid-prioritas {
  background-color: #fffbaf !important;
}

.datagrid-prioritas:hover {
  background-color: #fffbc0 !important;
}

/* rsuite CSS */
@import "rsuite/dist/rsuite.css";

/* DateRangePicker */
.rs-input {
  padding: 8.2px 14px !important;
}
.rs-picker-daterange {
  max-width: 100% !important;
}
.rs-input-group {
  background-color: transparent !important;
  border-radius: 5px !important;
  border-color: rgba(25, 118, 210, 0.5) !important;
}
.rs-input-group input {
  background-color: transparent !important;
  color: #1976d2 !important;
}
input::placeholder {
  color: #1976d2 !important;
}
.rs-input-group:hover {
  border-color: #1976d2 !important;
}
.rs-input-group:focus-within {
  outline: none !important;
}
.rs-input-group-addon {
  color: #1976d2 !important;
}
.rs-picker-popup {
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.75) !important;
}
